.instrumentName {
  @apply text-secondaryHover;
}
@media (max-width: 767px) {
  .instrumentName {
    width: calc(100vw - 15.5rem);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
